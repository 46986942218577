import React, { useEffect, useState } from 'react'
import styles from '../tables-global.module.css';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { Button } from '@mui/base';
import local_style from "./SubscribersTable.module.css"
import { Link } from "react-router-dom";


const SubscribersTable = ({ items, tableType, tenantName }) => {
    return (
        <div id={`${tableType}-table`} data-testid={`${tableType}-table`}>
            <TableContainer className={styles.table_container}>
                <Table stickyHeader className={styles.esc__table}>
                    <TableHead className={styles.esc__table_head}>
                        <TableRow className={styles.esc__table_header__row}>
                            <TableCell className={`${styles.esc__table_header} ${styles.esc__table__name} ${styles.esc__table_cell} ${local_style.table_type}`}>
                                {tableType === "subscriptions" ? "Event Sources" : "Subscribers"}
                            </TableCell>
                            <TableCell className={`${styles.esc__table_header} ${styles.esc__table_header__description}`} align="left">
                                <div className={styles.esc__table_header__row__div}>
                                    <div className={styles.esc__table_header__divider}></div>
                                    <div className={styles.esc__table_header__title}>
                                        Description
                                    </div>
                                </div>
                            </TableCell>
                            <TableCell className={`${styles.esc__table_header} ${styles.esc__table_header__action}`} align="left">
                                <div className={styles.esc__table_header__row__div}>
                                    <div className={styles.esc__table_header__divider}></div>
                                    <div className={`${styles.esc__table_header__title} ${styles.esc__table_action}`}>
                                        Subscriptions
                                    </div>
                                </div>
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody className={styles.esc__table_body}>
                        {items.map((item, index) => {
                            return (<TableRow
                                key={index}
                                className={styles.esc__table__row}
                            >
                                <TableCell
                                    className={`${styles.esc__table__cell} ${local_style.esc__table__name}`}
                                    component="th" scope="row"
                                >
                                    <div className={styles.esc__table__name_div}>
                                        <Link to={`/${tableType}-management/${tenantName}/${item.name}`} className={local_style.item_name}>
                                            {item.name}
                                        </Link>
                                    </div>
                                </TableCell>
                                <TableCell
                                    className={`${styles.esc__table__cell} ${local_style.esc__table_description}`}
                                    component="th" scope="row"
                                >
                                    <span>{item.description}</span>
                                </TableCell>
                                <TableCell
                                    className={`${styles.esc__table__cell} ${local_style.esc__table__subscriptions}`}
                                    component="th" scope="row"
                                >
                                    <span className={local_style.esc__table__subscriptions__text}>
                                        *Placeholder
                                    </span>
                                </TableCell>

                            </TableRow>
                            )
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    );
}

export default SubscribersTable;