import { Auth } from '@aws-amplify/auth';
const aws4fetch = require('aws4fetch');

const getCurrentUser = async ()=>  {
    try {
        authConfigure()
        const user = await Auth.currentAuthenticatedUser();
        return user;
    } catch {
        Auth.federatedSignIn({customProvider: 'FederateOIDC'})
            .then((credentials) =>
            console.log("SignIn successful"))
            .catch((e) => console.log(e))
        return null;
    }
};

const authConfigure = () => {
    Auth.configure({
        Auth: {
            userPoolId: 'us-west-2_0AWJRTHP2',
            userPoolWebClientId: '5c6dl0526kfj6qapf81sbgf7cm',
            identityPoolId: 'us-west-2:417c1ab5-b46a-4cb4-bb15-08a509e8b494',
            region: 'us-west-2'
        },
        oauth: {
            domain: 'configstoreui-test.auth.us-west-2.amazoncognito.com',
            scope: ['openid'],
            responseType: 'code',
            redirectSignIn: window.location.protocol + '//' + window.location.host,
            redirectSignOut: window.location.protocol + '//' + window.location.host,
        }
    });
}

const getAWSClient = async() => {
    const credentials = await Auth.currentCredentials();
    const aws = new aws4fetch.AwsClient({
        service: "execute-api",
        region: "us-west-2",
        accessKeyId: credentials.accessKeyId,
        secretAccessKey: credentials.secretAccessKey,
        sessionToken: credentials.sessionToken,
        retries: 0
    });
    return aws;
}



export { getCurrentUser, getAWSClient };