import { Card, Typography, CardActions, Divider } from '@mui/material';
import { Button } from '@mui/base';
import styles from './EventSourceCard.module.css';
import cardStyles from '../Cards.module.css';

const EventSourceCard = ({ title, desc, onClick }) => {
    return (
        <Card variant="outlined" className={styles.card__size}>
            <div className={cardStyles.card__header_div}>
                <Typography sx={{ fontSize: 10 }} className={cardStyles.card__upper_subtitle} gutterBottom>
                    GFT-DataServices-Team
                </Typography>
                <Typography variant="h5" component="div" className={cardStyles.card__title}>
                    {title}
                </Typography>
            </div>
            <Typography variant="body2" className={styles.description} >
                {desc}
            </Typography>
            <CardActions className={styles.card_actions}>
                <Button
                    className={styles.button}
                    onClick={onClick}
                >
                    Manage
                </Button>
            </CardActions>
        </Card>
    );
}

export default EventSourceCard;

