// React import
import React, { useEffect, useState } from 'react'
import { Link, useParams } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';

// 3rd Local import
import { Alert, Box, Button, Grid, Icon, Pagination, PaginationItem, Paper, TextField, Typography } from '@mui/material';
import AddReactionIcon from '@mui/icons-material/AddReaction';
import EventSourceCatalogIcon from '../../assets/catalogicon.svg';

// 3rd Party-Libraries import
import styles from './Dashboard.module.css';
import '../../global.css'
import { getSubscribers } from '../../services/SubscribersService';
import LightIconTextButton from '../../components/Buttons/LightIconTextButton/LightIconTextButton';
import CreateSubscriberModal from '../../components/Modals/CreateSubscriberModal/CreateSubscriberModal';
import SubscribersTable from '../../components/Tables/SubscribersTable/SubscribersTable';
import usePagination from '../../hooks/usePagination/usePagination';
import FormSearchField from '../../components/FormFields/FormSearchField/FormSearchField';


const Dashboard = () => {

    // Variables from Redux
    const tenantFromRedux = useSelector((state) => state.subscribers.tenant)
    const subscribersFromRedux = useSelector((state) => state.subscribers.subscribers)
    const dispatch = useDispatch();

    // Local Variables
    const [tenant, setTenant] = useState([]);
    const [subscribers, setSubscribers] = useState([])

    // Create Subscriber Variables
    const [isCreateSubscriberModalOpen, setIsCreateSubscriberModalOpen] = useState(false)
    const [createSubscriberMessage, setCreateSubscriberMessage] = useState(null);

    const [tableTabOption, setTableTabOption] = useState("subscribers")
    const [subscriberPage, setSubscriberPage] = useState(1);
    const [subscribersPerPage, setSubscribersPerPage] = useState(10);
    const [subscribersAmount, setSubscribersAmount] = useState(1);
    const [totalSubscribersPages, setTotalSubscribersPages] = useState(1);
    const paginatedSubscribers = usePagination(subscribers, 10);

    const [searchSubscriberName, setSearchSubscriberName] = useState('');

    const { tenantName } = useParams();

    useEffect(() => {
        setTenant(tenantFromRedux)
    }, [tenantFromRedux])

    useEffect(() => {
        setSubscribers(subscribersFromRedux)
    }, [subscribersFromRedux])

    useEffect(() => {
        // get all subscribers (of the tenant)
        async function getSubscribersHelper(tenantName) {
            await getSubscribers(tenantName, dispatch);
        }

        if (tenantName) {
            getSubscribersHelper(tenantName);
        }
    }, [])

    useEffect(() => {
        if (subscribers) {
            setSubscribersAmount(subscribers.length);
        }
    }, [subscribers]);

    useEffect(() => {
        paginatedSubscribers.updateItemsPerPage(subscribersPerPage)
        setTotalSubscribersPages(Math.ceil(subscribersAmount / subscribersPerPage))
    }, [subscribersPerPage, subscribersAmount])

    // Manage modals
    const createSubscriberModalClose = () => {
        setIsCreateSubscriberModalOpen(false);
    }

    useEffect(() => {
        const tabOptionAllElements = document.querySelectorAll('[id*=dashboard-subscripers-subscription-table-tab-option]')
        console.log("Element: ", tabOptionAllElements)
        tabOptionAllElements.forEach(element => {
            element.style.color = "#808080"
            element.style.borderBottom = "2px solid transparent"
        })

        const tabOptionElement = document.getElementById(`dashboard-subscripers-subscription-table-tab-option-${tableTabOption}`)
        tabOptionElement.style.color = "#0070F0"
        tabOptionElement.style.borderBottom = "2px solid #0070F0"
    }, [tableTabOption])

    const changeSubscriberPageNumber = (event, currentPage) => {
        setSubscriberPage(currentPage);
        paginatedSubscribers.jump(currentPage);
    };

    useEffect(() => {
        if (!searchSubscriberName.length) {
            paginatedSubscribers.updateData(subscribers);
            setSubscribersAmount(subscribers.length);
        }
        else if (searchSubscriberName.length > 2) {
            const newItems = subscribers.filter(({ name }) => name.startsWith(searchSubscriberName));
            paginatedSubscribers.updateData(newItems);
            setSubscribersAmount(newItems.length);
        }
    }, [searchSubscriberName])

    return (
        <div className={styles.dashboard__div}>
            <Grid container spacing={2}>
                <Grid item xs={10}>
                    <div className={styles.dashboard_subtitle__div}>
                        <span className={styles.dashboard_subtitle}>
                            Welcome
                        </span>
                    </div>
                    <div className={styles.dashboard_title__div}>
                        <span className={styles.dashboard_title}>
                            {tenantName ?? ""}
                        </span>
                    </div>
                </Grid>

                <Grid item xs={2}>

                    <div className={styles.dashboard__tenant_button_div}>
                        <Link to='/'>
                            <Button
                                variant='contained'
                                className={styles.dashboard__tenant_button}
                                style={{ textTransform: 'none' }}
                            >
                                Tenants
                            </Button>
                        </Link>
                    </div>
                </Grid>
                <Grid
                    container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center"
                    className={styles.dashboard__icon_buttons__row}
                >
                    <div className={styles.dashboard__icon_button_div}>
                        <div className={styles.dashboard__icon_button_div__wrapper}>
                            <Link to={`/event-source-catalog/${tenantName}`}>
                                <LightIconTextButton
                                    name='Event Source Catalog'
                                    icon={
                                        <div className={`${styles.dashboard__button__icon_div}  ${styles.dashboard__event_source_icon_div}`}>
                                            <img className={`GFT--action-color ${styles.dashboard__button_icon}`}
                                                src={EventSourceCatalogIcon} />
                                        </div>
                                    }
                                />
                            </Link>
                        </div>
                    </div>
                    <div className={styles.dashboard__icon_button_div}>
                        <div className={styles.dashboard__icon_button_div__wrapper}>
                            <LightIconTextButton
                                name='+ New Subscriber'
                                icon={
                                    <div className={`${styles.dashboard__button__icon_div}  ${styles.dashboard__new_subscriber_icon_div}`}>
                                        <AddReactionIcon className={`GFT--primary-color ${styles.dashboard__button_icon}`} />
                                    </div>
                                }
                                onClick={(e) => setIsCreateSubscriberModalOpen(true)}
                            />
                        </div>
                    </div>

                </Grid>
            </Grid>


            <Grid container className={styles.subscriptions_subscribers_table__div}>
                <Box className={styles.subscriptions_subscribers_table__box}>
                    <Paper className={styles.subscriptions_subscribers_table__paper}>
                        <Grid container direction={"row"} className={styles.subscriptions_subscribers_table__tab__grid}>
                            <Grid item>
                                <div className={styles.subscriptions_subscribers_table__tab}>
                                    <div>
                                        <Button className={styles.subscriptions_subscribers_table__tab__item}
                                            id="dashboard-subscripers-subscription-table-tab-option-subscriptions"
                                            data-testid="dashboard-subscripers-subscription-table-tab-option-subscriptions"
                                            onClick={(e) => setTableTabOption("subscriptions")}>
                                            Subscriptions
                                        </Button>
                                    </div>
                                    <div>
                                        <Button
                                            onClick={(e) => setTableTabOption("subscribers")}
                                            className={styles.subscriptions_subscribers_table__tab__item}
                                            data-testid="dashboard-subscripers-subscription-table-tab-option-subscribers"
                                            id="dashboard-subscripers-subscription-table-tab-option-subscribers">
                                            Subscribers
                                        </Button>
                                    </div>
                                </div>
                            </Grid>


                            <Grid>
                                <div className={styles.esc_header__action_div}>
                                    <div className={styles.filtering__div}>
                                        <FormSearchField value={searchSubscriberName} onChange={setSearchSubscriberName} />
                                    </div>
                                </div>
                            </Grid>
                        </Grid>
                    </Paper>
                </Box>

                <Grid container className={styles.subscriptions_subscribers_table__wrapper}>
                    {/* 
                        Currently, only the subscriptions list is emtpy as we are
                        prioritizing the subscribers table over the subscriptions
                        table. The subscriptions table feature will be added in
                        the future.
                    */}
                    <SubscribersTable
                        items={(tableTabOption === "subscriptions") ? [] : paginatedSubscribers.currentData()}
                        tableType={tableTabOption}
                        tenantName={tenantName}
                    />
                </Grid>
                <Grid
                    className={styles.pagination}
                    container
                    direction="row"
                    justifyContent="flex-end"
                    alignItems="baseline"
                >
                    <Typography variant="body2">{subscriberPage} of {totalSubscribersPages}</Typography>
                    <Pagination
                        renderItem={(item) => (
                            item.type === 'next' || item.type === 'previous' ?
                                <PaginationItem
                                    {...item}
                                /> : <></>
                        )}
                        count={totalSubscribersPages}
                        size={"large"}
                        page={subscriberPage}
                        onChange={changeSubscriberPageNumber}
                    />
                </Grid>

                <CreateSubscriberModal
                    isOpen={isCreateSubscriberModalOpen}
                    handleClose={createSubscriberModalClose}
                    tenantName={tenant}
                    dispatch={dispatch}
                    setMessage={setCreateSubscriberMessage}
                />

                <div className={styles.alerts__div}>
                    {createSubscriberMessage && createSubscriberMessage === 'Success' ?
                        <Alert severity="success">Creating subscriber was successful</Alert> :
                        <></>
                    }
                    {createSubscriberMessage && createSubscriberMessage === 'Failed' ?
                        <Alert severity="error">Creating subscriber failed</Alert> :
                        <></>
                    }
                </div>
            </Grid>
        </div>
    );
}

export default Dashboard;