import React, { useEffect, useState } from 'react'
import { getAWSClient } from '../../services/AuthService';
import { getTenants } from '../../services/TenantsService';
import { useDispatch, useSelector } from 'react-redux';
import styles from './Tenants.module.css';
import { Grid } from '@mui/material';
import TenantCard from '../../components/Cards/TenantCard/TenantCards';
import { Outlet, Link } from "react-router-dom";

const Tenants = () => {

    // Variables from Redux
    const tenantsFromRedux = useSelector((state) => state.tenants.tenants)
    const dispatch = useDispatch();

    // Local Variables
    const [tenants, setTenants] = useState([]);

    // Get all the tenants
    useEffect(() => {
        setTenants(tenantsFromRedux)
    }, [tenantsFromRedux])

    useEffect(() => {
        async function getTenantsHelper(awsClient) {
            await getTenants(awsClient, dispatch);
        }

        if (!tenants || !tenants.length) {
            getTenantsHelper(getAWSClient);
        }
    }, [])

    return (
        <div className={styles.tenants__div}>
            <div className={styles.tenants__title_div}>
                <h1 className={styles.tenants__title}>
                    Tenants
                </h1>
            </div>

            <div className={styles.tenants__section_div}>
                <h2 className={styles.tenants__section_title}>
                    All
                </h2>
            </div>

            <div className={styles.tenants__grid}>
                <Grid container spacing={2}>

                    {tenants.map((tenant, tenantIdx) => {
                        return (
                            <Grid key={tenantIdx} item xs={3}>
                                <Link to={`/dashboard/${tenant.name}`} className='link__no_decoration' >
                                    <div className={styles.tenant_card__div}>
                                        <TenantCard 
                                            title={tenant.name}
                                            description="This is a temporary description for testing PubSub UI."    
                                        />
                                    </div>
                                </Link>
                            </Grid>
                        );
                    })}
                </Grid>
            </div>
        </div>
    );
}

export default Tenants;