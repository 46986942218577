// React import
import { useEffect, useState } from 'react';

// 3rd Party-Libraries import
import {
    Dialog, Table, TableHead, TableRow, TableCell, TableBody,
    DialogContent, DialogTitle, Typography, Grid, Switch
} from '@mui/material';

// Local import
import styles from './ManageSubscribersModal.module.css';
import ModalButton from "../../Buttons/ModalButtons/ModalButton";
import { getSubscriptionSubscribers, manageSubscriptions } from "../../../services/SubscriptionsService";

const ManageSubscribersModal = ({ isOpen = false, handleClose, event, tenant, subscribers, setMessage }) => {

    const [subscriptionSubscribers, setSubscriptionSubscribers] = useState([]);
    const [subscriberIsSubscribedMap, setSubscriberIsSubscribedMap] = useState(new Map());
    const [isSubscriptionsLoaded, setIsSubscriptionsLoaded] = useState(false);
    const [isSubscriptionsChanged, setIsSubscriptionsChanged] = useState(false);
    const [form, setForm] = useState(new Map());

    // Initializes a Map of subscribers and
    // a boolean of whether they are subscribed
    // Retrieves list of subscription subscribers
    // from API
    useEffect(() => {
        let map = new Map();
        subscribers.forEach((subscriber) => {
            map.set(subscriber.name, false)
        });
        setSubscriberIsSubscribedMap(map);

        const eventName = event ? event.name : '';
        async function getSubscriptionSubscribersHelper(tenant, eventName) {
            await getSubscriptionSubscribers(tenant, eventName, setSubscriptionSubscribers);
        }
        if (isOpen === true) {
            getSubscriptionSubscribersHelper(tenant, eventName);
        }
    }, [isOpen]);

    // Updates the subscriberIsSubscribedMap
    // with values returned from API
    useEffect(() => {
        let map = new Map(subscriberIsSubscribedMap);

        subscriptionSubscribers.forEach((subscriptionSubscriber) => {
            map.set(subscriptionSubscriber.subscriberName, true);
        });
        setSubscriberIsSubscribedMap(map);
        setIsSubscriptionsLoaded(true);
    }, [subscriptionSubscribers]);

    const handleCloseHelper = () => {
        setSubscriberIsSubscribedMap(new Map());
        setIsSubscriptionsChanged(false);
        setIsSubscriptionsLoaded(false);
        setForm(new Map());
        handleClose();
    };

    //  Adds changed values to the form
    //  Handles making a change to the subscriber list
    //  Handles background color of table rows
    const handleSwitch = (subscriberName, subscriberID) => {
        if (form.has(subscriberName)) {
            form.delete(subscriberName);
            const element = document.getElementById(`esc-subscriber-table-row-${subscriberID}`);
            if (element) element.style.backgroundColor = '#fff';
        } else {
            form.set(subscriberName, !subscriberIsSubscribedMap.get(subscriberName));
            const element = document.getElementById(`esc-subscriber-table-row-${subscriberID}`);
            if (element) element.style.backgroundColor = '#E6F1FF';
        }

        let map = new Map(subscriberIsSubscribedMap);
        map.set(subscriberName, !subscriberIsSubscribedMap.get(subscriberName));
        setSubscriberIsSubscribedMap(map);

        setIsSubscriptionsChanged(form.size > 0);
    };

    // Handles form submission
    const manageSubscriptionSubscribersHelper = async (e) => {
        e.preventDefault();

        let createList = [];
        let deleteList = [];

        form.forEach((action, subscriber) => {
            if (action === true) {
                createList.push(subscriber);
            } else if (action === false) {
                deleteList.push(subscriber);
            }
        });

        // Submits 2 api calls at the same time if
        // there are both create and delete actions
        let requests = [];

        if (createList.length > 0) {
            const createBody = JSON.stringify({
                subscriberNames: createList,
                subscriptions: [event.name],
                action: 'create'
            })
            const createResponse = manageSubscriptions(tenant, createBody);
            requests.push(createResponse);
        }

        if (deleteList.length > 0) {
            const deleteBody = JSON.stringify({
                subscriberNames: deleteList,
                subscriptions: [event.name],
                action: 'delete'
            })
            const deleteResponse = manageSubscriptions(tenant, deleteBody);
            requests.push(deleteResponse)
        }

        Promise.all(requests)
            .then((results) => {
                let successCount = 0;
                let errorCount = 0;
                results.forEach((result) => {
                    if (result.status === 200) {
                        successCount++;
                    } else {
                        errorCount++;
                    }
                });
                if (successCount > 0 && errorCount === 0) {
                    setMessage('Success');
                } else if (successCount > 0 && errorCount > 0) {
                    setMessage('Partial');
                } else {
                    setMessage('Failed');
                }
            });

        handleCloseHelper();
    }

    return (
        <Dialog
            className={styles.modal__box}
            fullWidth={true}
            maxWidth={"md"}
            open={isOpen}
            onClose={handleCloseHelper}
        >
            <DialogTitle className={styles.modal__title}>Manage subscribers</DialogTitle>
            <DialogTitle className={styles.modal__subtitle}>Subscribing to {event ? event.name : ""}</DialogTitle>
            <Grid
                container
                direction="column"
                justifyContent="flex-start"
                alignItems="stretch"
            >
                <DialogContent className={styles.modal__table}>
                    <Table stickyHeader>
                        <TableHead>
                            <TableRow>
                                <TableCell className={styles.modal__table_headers}>Subscribers</TableCell>
                                <TableCell className={styles.modal__table_headers} align="left">Description</TableCell>
                                <TableCell className={styles.modal__table_headers} align="center">Subscribed</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody className={styles.modal__table_body}>
                            {subscribers.map((subscriber) => (
                                <TableRow
                                    key={subscriber.id}
                                    id={`esc-subscriber-table-row-${subscriber.id}`}
                                    data-testid={`esc-subscriber-table-row-${subscriber.id}`}
                                >
                                    <TableCell
                                        className={styles.modal__row}
                                        component="th" scope="row"
                                    >
                                        {subscriber.name}
                                    </TableCell>
                                    <TableCell
                                        className={styles.modal__row}
                                        component="th" scope="row"
                                    >
                                    </TableCell>
                                    <TableCell
                                        align="center"
                                        className={styles.modal__row}
                                        component="th" scope="row"
                                    ><div>
                                            <Switch
                                                checked={
                                                    subscriberIsSubscribedMap.has(subscriber.name)
                                                        ? subscriberIsSubscribedMap.get(subscriber.name)
                                                        : false
                                                }
                                                disabled={!isSubscriptionsLoaded}
                                                onChange={(e) => handleSwitch(subscriber.name, subscriber.id)}
                                            />
                                        </div>
                                    </TableCell>

                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                    {subscribers.length === 0 &&
                        <Typography align={"center"} className={`GFT--action-color ${styles.modal__empty}`}>No
                            Subscribers to Display.</Typography>
                    }
                </DialogContent>
                <div className={styles.modal__buttons_row}>
                    <ModalButton
                        name='Cancel'
                        onClick={(e) => handleCloseHelper()}
                    />
                    <ModalButton
                        name='Submit'
                        isDisabled={!isSubscriptionsChanged}
                        onClick={(e) => manageSubscriptionSubscribersHelper(e)}
                    />
                </div>
            </Grid>
        </Dialog>
    )
}

export default ManageSubscribersModal;