const fetchSettings = async () => {
    try {
        const response = await fetch(`${process.env.PUBLIC_URL}/settings.json`, {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        });
        const settingsJson = await response.json();
        return settingsJson
    } catch (e) {
        alert("There was a problem retrieving settings from backend", e);
    }
}

export const getUrl = async () => {
    try {
        const settings = await fetchSettings()
        return settings["APIEndPoint"]
    } catch (e) {
        alert("GetUrl failed")
    }
}

  // Check if string contains only uppercase letters (A-Z),
  // lowercase letters (a-z), numbers (0-9), hyphens (-),
  // and underscores (_)
  export const isStringValid = (newString) => {
    var validRegExp=new RegExp("^[0-9A-Za-z_-]+$");
    return validRegExp.test(newString);
}