// 3rd Party-Libraries import
import { FormControl, InputLabel, Select, MenuItem } from '@mui/material';

// Local import
import styles from './FormDropdownField.module.css'
import { VALID_TEXTFIELD_BORDER_COLOR } from '../../../utils/constants';

const FormDropdownField = ({ label = '', value = '', options = [], handleChange }) => {

  return (
    <FormControl fullWidth>
      <InputLabel id={`select-${label}`} htmlFor={`select-${label}`}>{label}</InputLabel>
      <Select
        labelId={`select-${label}`}
        id={`select-${label}`}
        label={label}
        value={value}
        onChange={handleChange}
        className={styles.form_dropdown_field}
        inputProps={{
          textalign: "left",
          'data-testid': `select-${label}`
        }}
        sx={{
          ".MuiOutlinedInput-notchedOutline": {
            borderColor: VALID_TEXTFIELD_BORDER_COLOR,
            textalign: 'left',
          },
          "& .MuiInputLabel-root": {
            color: '#757575',
            fontFamily: 'Amazon Ember',
            fontStyle: 'normal',
            fontWeight: '400',
            fontSize: '16px'
          },
          "&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
          {
            borderColor: VALID_TEXTFIELD_BORDER_COLOR,
            textAlign: 'left',
          }
        }}
      >
        {options.map((opt, optIndex) => {
          return (
            <MenuItem key={optIndex} value={opt.value}>{opt.name}</MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
}

export default FormDropdownField;