import {updateEventSources} from '../redux/slices/eventSourceSlice'
import { getUrl } from '../utils/sharedFunctions';
import { getAWSClient } from './AuthService';

const aws4fetch = require('aws4fetch');

export const getEventSources = async(dispatch) => {
    try {
        const aws = await getAWSClient();
        const domain = await getUrl();
        let url = `${domain}/streams/`;
        const res = await aws.fetch(url,
            {method: 'GET'})
        try {
            res.json()
                .then((json) => {
                        return json.streams
                    }
                ).then((json) => {
                    const eventSources = json
                    dispatch(updateEventSources({eventSources: eventSources, resolved: true}))
                })
                .catch((e) => console.log("getEventSources failed", e));
                dispatch(updateEventSources({eventSources: [], resolved: true}))
        } catch (e) {
            console.log("getEventSources failed", e)
        }
    } catch (e) {
        console.log("getEventSources failed", e)
    }
}