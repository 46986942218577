// 3rd Party-Libraries import
import { Button } from "@mui/material";

// Local import
import styles from './ModalButton.module.css'

const ModalButton = ({name, isDisabled=false, onClick}) => {
    return (
        <Button 
        disabled={isDisabled} 
        onClick={onClick} 
        className={styles.modal_button}
        variant="text"
        style={{ textTransform: 'none' }}>
            {name}
        </Button>
    );
}

export default ModalButton;