import { updateTenants } from '../redux/slices/tenantSlice';
import { getUrl } from '../utils/sharedFunctions';

const aws4fetch = require('aws4fetch');

export const getTenants = async(awsClient, dispatch) => {
    try {
        const aws = await awsClient();
        const domain = await getUrl();
        let url = `${domain}/tenant/`;
        const res = await aws.fetch(url,
            {method: 'GET'})
        try {
            res.json()
                .then((json) => {
                        return json.tenants
                    }
                ).then((json) => {
                    const tenants = json
                    dispatch(updateTenants({tenants: tenants, resolved: true}))
                })
                .catch((e) => console.log("getTenants failed", e));
                dispatch(updateTenants({tenants: [], resolved: true}))
        } catch (e) {
            console.log("getTenants failed", e)
        }
    } catch (e) {
        console.log("getTenants failed", e)
    }
}