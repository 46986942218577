import {getUrl} from "../utils/sharedFunctions";
import { getAWSClient } from './AuthService';

const aws4fetch = require('aws4fetch');

export const getSubscriptionSubscribers = async (tenantName, subscriptionName, setSubscriptionSubscribers) => {
    try {
        const aws = await getAWSClient();
        const domain = await getUrl();
        let url = `${domain}/tenant/${tenantName}/subscription/${subscriptionName}`;
        const res = await aws.fetch(url,
            {method: 'GET'});
        try {
            res.json()
                .then((json) => {
                    if (res.status === 200) {
                        setSubscriptionSubscribers(json.subscriptionSubscribersInfoList);
                    } else {
                        setSubscriptionSubscribers([]);
                    }
                }).catch((e) => console.log("getSubscriptionSubscribers failed", e));
        } catch (e) {
            console.log("getSubscriptionSubscribers failed", e);
        }
    } catch (e) {
        console.log("getSubscriptionSubscribers failed", e);
    }
}

export const manageSubscriptions = async (tenantName, body) => {
    try {
        const aws = await getAWSClient();
        const domain = await getUrl();
        let url = `${domain}/tenant/${tenantName}/subscribers`;
        const res = await aws.fetch(url,
            { method: 'PUT', body: body });
        return res;
    }  catch (e) {
        console.log("manageSubscriptions failed", e);
    }
}