// 3rd Party-Libraries import
import { Button } from '@mui/material';

// Local import
import styles from './LightIconTextButton.module.css'

const LightIconTextButton = ({ name, icon, onClick }) => {
    return (
        <Button
            className={styles.light_button}
            size="small"
            variant="text"
            style={{ textTransform: 'none' }}
            onClick={onClick}
        >
            <div className={styles.light_button__row}>
                <div>{icon}</div> <div className={styles.light_button__name}>{name}</div>
            </div></Button>
    );
}

export default LightIconTextButton;