import { updateSubscribers } from '../redux/slices/subscriberSlice';
import { getUrl } from '../utils/sharedFunctions';
import { getAWSClient } from './AuthService';

const aws4fetch = require('aws4fetch');

export const getSubscribers = async (tenantName, dispatch) => {
    try {
        const aws = await getAWSClient();
        const domain = await getUrl();
        let url = `${domain}/tenant/${tenantName}/subscriber`;
        const res = await aws.fetch(url,
            { method: 'GET' })
        try {
            res.json()
                .then((json) => {
                    return json.subscribers
                }
                ).then((json) => {
                    const subscribers = json
                    dispatch(updateSubscribers({ tenant: tenantName, subscribers: subscribers, resolved: true }))
                })
                .catch((e) => console.log("getSubscribers failed", e));

        } catch (e) {
            console.log("getSubscribers failed", e)
            dispatch(updateSubscribers({ tenant: tenantName, subscribers: [], resolved: true }))
        }
    } catch (e) {
        console.log("getSubscribers failed", e)
        dispatch(updateSubscribers({ tenant: tenantName, subscribers: [], resolved: true }))
    }
}

export const createSubscriber = async (tenantName, body, dispatch, setMessage) => {
    try {
        const aws = await getAWSClient();
        const domain = await getUrl();
        let url = `${domain}/tenant/${tenantName}/subscriber`;
        const res = await aws.fetch(url,
            { method: 'POST', body: body })
        try {
            res.json()
                .then((json) => {
                    getSubscribers(tenantName, dispatch)
                    if(json.status === 'Success' || json.status === 201){
                        setMessage('Success')
                    }
                    else{
                        setMessage('Failed')
                    }
                }).catch((e) => {
                    console.log("createSubscriber failed", e)
                    setMessage('Failed')
                })
        } catch (e) {
            console.log("createSubscriber failed", e)
            setMessage('Failed')
        }
    } catch (e) {
        console.log("createSubscriber failed", e)
        setMessage('Failed')
    }
}

export const getSubscriberSubscriptions = async (tenantName, susbcriberName, setEventSources) => {
    try {
        const aws = await getAWSClient();
        const domain = await getUrl();
        let url = `${domain}/tenant/${tenantName}/subscriber/${susbcriberName}`;
        const res = await aws.fetch(url,
            {method: 'GET'});
            res.json()
                .then((json) => {
                    if (res.status === 200) {
                        setEventSources(json.subscriptionsInfoList);
                    } else {
                        setEventSources([]);
                    }
                }).catch((e) => console.log("getSubscriberSubscriptions failed", e));
    } catch (e) {
        console.log("getSubscriberSubscriptions failed", e);
    }
}