import {createSlice} from "@reduxjs/toolkit"

const initialState = {
    eventSources: [],
    resolved: false
}

export const eventSourcesReducer = createSlice({
    name: 'eventSource',
    initialState,
    reducers: {
        updateEventSources: (state, action) => {
            state.eventSources = action.payload.eventSources
            state.resolved = action.payload.resolved
        }
    }
});

export const {updateEventSources} = eventSourcesReducer.actions;

export default eventSourcesReducer.reducer;