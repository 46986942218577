import React, { useEffect, useState } from 'react'
import { getCurrentUser, getAWSClient } from '../../services/AuthService';
import '../../global.css'
import styles from './Layout.module.css'


const Layout = ({ children }) => {

    // Local Variables
    const [user, setUser] = useState('');

    useEffect(() => {
        getCurrentUser().then((user) => {
            setUser(user.username)
        }).catch((e) => console.log("Failed getting current user"))
    }, [])

    return (
        user ?
            <div className={styles.main}>
                <div className={styles.row}>
                    <div className={styles.left_column}>
                        <div className={styles.left_column__wrapper}>
                            {children}
                        </div>
                    </div>
                </div>
            </div> : <div></div>
    );
}

export default Layout;