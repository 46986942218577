import React, { useEffect, useState } from 'react'
import { getAWSClient } from '../../services/AuthService';
import { getEventSources } from "../../services/EventSourceService";
import { getSubscribers } from "../../services/SubscribersService";
import { useDispatch, useSelector } from 'react-redux';
import styles from './EventSourceCatalog.module.css';
import { Alert, Grid, IconButton, Input, InputLabel, OutlinedInput, Pagination, PaginationItem, TextField, Typography } from '@mui/material';
import { Button as BasicButton, FormControl } from '@mui/base';
import { ArrowBackIosNew } from '@mui/icons-material';
import usePagination from '../../hooks/usePagination/usePagination';
import EventSourceCard from '../../components/Cards/EventSourceCard/EventSourceCards.js';
import ManageSubscribersModal from '../../components/Modals/ManageSubscribersModal/ManageSubscribersModal';
import { Link, useParams } from "react-router-dom";
import EventSourceTable from '../../components/Tables/EventSourcesTable/EventSourcesTable';
import GridViewIcon from '@mui/icons-material/GridView';
import ListIcon from '@mui/icons-material/List';
import FormTextField from '../../components/FormFields/FormTextField/FormTextField';
import { VALID_TEXTFIELD_BORDER_COLOR } from '../../utils/constants';

const EventSourceCatalog = () => {

    // Variables from Redux
    const eventSourcesRedux = useSelector((state) => state.eventSources.eventSources)
    const subscribersFromRedux = useSelector((state) => state.subscribers.subscribers)

    const dispatch = useDispatch();
    const { tenantName } = useParams();

    // Local Variables
    const [eventSources, setEventSources] = useState([]);
    const [selectedEvent, setSelectedEvent] = useState(null);
    const [subscribers, setSubscribers] = useState([]);
    const [page, setPage] = useState(1);
    const [eventSourcesPerPage, setEventSourcesPerPage] = useState(15);
    const [eventSourcesNumber, setEventSourcesNumber] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const paginatedEventSources = usePagination(eventSources, 15);
    const [isManageSubscribersModalOpen, setIsManageSubscribersModalOpen] = useState(false);
    const [alertMessage, setAlertMessage] = useState("");
    const [viewOption, setViewOption] = useState('listView');
    const [eventNameToSearch, setEventNameToSearch] = useState('');


    // Get all the event sources
    useEffect(() => {
        setEventSources(eventSourcesRedux);
    }, [eventSourcesRedux]);

    useEffect(() => {
        setSubscribers(subscribersFromRedux);
    }, [subscribersFromRedux]);

    useEffect(() => {
        setEventSourcesNumber(eventSources.length);
    }, [eventSources]);

    useEffect(() => {
        paginatedEventSources.updateItemsPerPage(eventSourcesPerPage)
        setTotalPages(Math.ceil(eventSourcesNumber / eventSourcesPerPage))
    }, [eventSourcesPerPage, eventSourcesNumber])

    useEffect(() => {
        if (!eventNameToSearch.length) {
            paginatedEventSources.updateData(eventSources);
            setEventSourcesNumber(eventSources.length);
        }
        else if (eventNameToSearch.length > 2) {
            const newItems = eventSources.filter(({ name }) => name.startsWith(eventNameToSearch));
            paginatedEventSources.updateData(newItems);
            setEventSourcesNumber(newItems.length);
        }
    }, [eventNameToSearch])

    useEffect(() => {
        async function getEventSourcesHelper(awsClient) {
            await getEventSources(dispatch);
        }

        if (!eventSources || !eventSources.length) {
            getEventSourcesHelper();
        }

        async function getSubscribersHelper(tenantName) {
            await getSubscribers(tenantName, dispatch);
        }

        if (!subscribersFromRedux.length) {
            getSubscribersHelper(tenantName);
        }
    }, []);

    const changePageNumber = (event, currentPage) => {
        setPage(currentPage);
        paginatedEventSources.jump(currentPage);
    };

    const manageSubscribersModalClose = () => {
        setIsManageSubscribersModalOpen(false);
    }

    const openModal = (event) => {
        setSelectedEvent(event);
        setIsManageSubscribersModalOpen(true);
        setAlertMessage(null);
    }

    const changeViewOptionHelper = (newViewOption) => {
        setViewOption(newViewOption);
        setPage(1);
        if(newViewOption === 'listView'){
            setEventSourcesPerPage(15)
        }
        else{
            setEventSourcesPerPage(12)
        }
    }

    return (
        <div >
            <div className={styles.esc_header__div}>
                <Grid
                    container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center"
                    className={styles.esc_title__div}
                >
                    <div className={styles.arrow_back_icon__div}>
                        <Link to={`/dashboard/${tenantName}`}>
                            <ArrowBackIosNew sx={{ pr: 1, color: '#808080' }} className={styles.arrow_back_icon} />
                        </Link>
                    </div>
                    <h1 className={styles.title}>
                        Event Source Catalog
                    </h1>
                </Grid>

                <div className={styles.esc_header__action_div}>
                    <div className={styles.filtering__div}>
                        <TextField
                            label="Search"
                            className={`${styles.form_text_field}`}
                            size="small"
                            sx={{
                                "& .MuiInputLabel-root": {
                                    color: '#757575',
                                    fontFamily: 'Amazon Ember',
                                    fontStyle: 'normal',
                                    fontWeight: '400',
                                    fontSize: '13px',
                                    lineHeight: "12px"
                                },
                                "& .MuiOutlinedInput-root": {
                                    "& > fieldset": {
                                        borderColor: VALID_TEXTFIELD_BORDER_COLOR,
                                        height: "32px"
                                    },
                                },
                                "& .MuiOutlinedInput-root.Mui-disabled": {
                                    "& > fieldset": { borderColor: VALID_TEXTFIELD_BORDER_COLOR }
                                },
                                "& .MuiOutlinedInput-root.Mui-focused": {
                                    "& > fieldset": {
                                        borderColor: VALID_TEXTFIELD_BORDER_COLOR,
                                        border: `1px solid`
                                    }
                                },
                                "& .MuiOutlinedInput-input": {
                                    height: "12px"
                                }
                            }}
                            value={eventNameToSearch}
                            onChange={(e) => setEventNameToSearch(e.target.value)}
                        />
                    </div>

                    <div className={styles.view_options__div}>
                        <div className={styles.icon_button__div}>
                            <IconButton aria-label="esc-view-options-grid"
                                onClick={(e) => changeViewOptionHelper("cardView")}
                                className={styles.icon_button}
                                data-testid='card-view-button'
                                >
                                <GridViewIcon style={{ color: viewOption === 'cardView' ? "#0070F0" : "#808080" }}
                                    className={styles.esc_options__icon} />
                            </IconButton>
                        </div>

                        <div className={styles.icon_button__div}>
                            <IconButton aria-label="esc-view-options-list"
                                onClick={(e) => changeViewOptionHelper("listView")}
                                className={styles.icon_button}
                                data-testid='list-view-button'
                                >
                                <ListIcon style={{ color: viewOption === 'cardView' ? "#808080" : "#0070F0" }}
                                    className={styles.esc_options__icon} />
                            </IconButton>
                        </div>
                    </div>
                </div>
            </div>

            <div className={styles.grid}>
                {viewOption === 'cardView' ? <Grid container spacing={2}  data-testid='card-view-div'>
                    {
                        paginatedEventSources.currentData().map((eventSource, eventSourceIdx) => {
                            return (
                                <Grid key={eventSourceIdx} item xs={3}>
                                    <EventSourceCard
                                        title={eventSource.name}
                                        desc={eventSource.description}
                                        onClick={(e) => openModal(eventSource)}
                                    />
                                </Grid>
                            );
                        })
                    }
                </Grid> :
                    <div className={styles.esc_list_view__div} data-testid='list-view-div'>
                        <EventSourceTable
                            items={paginatedEventSources.currentData()}
                            onClick={openModal}
                        />
                    </div>
                }
            </div>
            <Grid
                className={styles.pagination}
                container
                direction="row"
                justifyContent="flex-end"
                alignItems="baseline"
            >
                <Typography variant="body2">{page} of {totalPages}</Typography>
                <Pagination
                    renderItem={(item) => (
                        item.type === 'next' || item.type === 'previous' ?
                            <PaginationItem
                                {...item}
                            /> : <></>
                    )}
                    count={totalPages}
                    size={"large"}
                    page={page}
                    onChange={changePageNumber}
                />
            </Grid>
            <ManageSubscribersModal
                isOpen={isManageSubscribersModalOpen}
                handleClose={manageSubscribersModalClose}
                subscribers={subscribers}
                event={selectedEvent}
                tenant={tenantName}
                setMessage={setAlertMessage}
            />

            <div className={styles.alerts__div}>
                {alertMessage && alertMessage === 'Success' ?
                    <Alert
                        severity="success"
                        onClick={() => { setAlertMessage(null) }}
                    >
                        Subscriptions updated successfully
                    </Alert> :
                    <></>
                }
                {alertMessage && alertMessage === 'Failed' ?
                    <Alert
                        severity="error"
                        onClick={() => { setAlertMessage(null) }}
                    >
                        Subscriptions failed to update
                    </Alert> :
                    <></>
                }
                {alertMessage && alertMessage === 'Partial' ?
                    <Alert
                        severity="warning"
                        onClick={() => { setAlertMessage(null) }}
                    >
                        Some subscriptions failed to update
                    </Alert> :
                    <></>
                }
            </div>
        </div>
    )
}

export default EventSourceCatalog;