import { Card, CardContent, Divider, Typography } from '@mui/material';
import styles from './TenantCard.module.css';
import cardStyles from '../Cards.module.css'

const TenantCard = ({ title, description = '' }) => {

    return (
        <Card variant="outlined" className={styles.card}>
            <div className={cardStyles.card__header_div}>
                <Typography sx={{ fontSize: 10 }} className={cardStyles.card__upper_subtitle} gutterBottom>
                    GFT-DataServices-Team
                </Typography>
                <Typography variant="h5" component="div" className={cardStyles.card__title}>
                    {title}
                </Typography>
            </div>

            <div>
                <Typography variant="body2" className={styles.description} >
                    {description}
                </Typography>
            </div>

            <CardContent>
                <Typography component="div" >

                </Typography>
            </CardContent>
        </Card>
    );
}

export default TenantCard;