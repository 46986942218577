import {createSlice} from "@reduxjs/toolkit"

const initialState = {
    tenants: [],
    resolved: false
}

export const tenantReducer = createSlice({
    name: 'tenants',
    initialState,
    reducers: {
        updateTenants: (state, action) => {
            state.tenants = action.payload.tenants
            state.resolved = action.payload.resolved
        }
    }
});

export const {updateTenants} = tenantReducer.actions;

export default tenantReducer.reducer;