import React, { useEffect, useState } from 'react'
import { getAWSClient } from '../../services/AuthService';
import { getEventSources } from "../../services/EventSourceService";
import { useDispatch, useSelector } from 'react-redux';
import styles from './SubscriberManagement.module.css';
import { Alert, Button, Grid, IconButton, Input, InputLabel, OutlinedInput, Pagination, PaginationItem, TextField, Typography } from '@mui/material';
import { ArrowBackIosNew } from '@mui/icons-material';
import usePagination from '../../hooks/usePagination/usePagination';
import { Link, useParams } from "react-router-dom";
import FormTextField from '../../components/FormFields/FormTextField/FormTextField';
import { VALID_TEXTFIELD_BORDER_COLOR } from '../../utils/constants';
import { getSubscriberSubscriptions, getSubscribers } from '../../services/SubscribersService';
import SubscriberManagementTable from '../../components/Tables/SubscriberManagementTable/SubscriberManagementTable';
import { manageSubscriptions } from '../../services/SubscriptionsService';
import FormSearchField from '../../components/FormFields/FormSearchField/FormSearchField';

const SubscriberManagement = () => {

    // Variables from Redux
    const eventSourcesRedux = useSelector((state) => state.eventSources.eventSources)

    const dispatch = useDispatch();
    const { tenantName, subscriberName } = useParams();

    // Local Variables
    const [eventSources, setEventSources] = useState([]);
    const [page, setPage] = useState(1);
    const [eventSourcesPerPage, setEventSourcesPerPage] = useState(15);
    const [eventSourcesNumber, setEventSourcesNumber] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [subscriptions, setSubscriptions] = useState([]);
    const paginatedEventSources = usePagination(eventSources, 15);
    const [alertMessage, setAlertMessage] = useState("");
    const [eventNameToSearch, setEventNameToSearch] = useState('');

    const [subscriptionsMap, setSubscriptionsMap] = useState(new Map());
    const [selectedEventsMap, setSelectedEventsMap] = useState(new Map());
    const [isCheckAll, setIsCheckAll] = useState(false);

    const [canEnable, setCanEnable] = useState(false);
    const [canDisable, setCanDisable] = useState(false);

    // Get all the event sources
    useEffect(() => {
        setEventSources(eventSourcesRedux);
    }, [eventSourcesRedux]);

    useEffect(() => {
        setEventSourcesNumber(eventSources.length);
    }, [eventSources]);

    useEffect(() => {
        paginatedEventSources.updateItemsPerPage(eventSourcesPerPage)
        setTotalPages(Math.ceil(eventSourcesNumber / eventSourcesPerPage))
    }, [eventSourcesPerPage, eventSourcesNumber])

    useEffect(() => {
        if (!eventNameToSearch.length) {
            paginatedEventSources.updateData(eventSources);
            setEventSourcesNumber(eventSources.length);
        }
        else if (eventNameToSearch.length > 2) {
            const newItems = eventSources.filter(({ name }) => name.startsWith(eventNameToSearch));
            paginatedEventSources.updateData(newItems);
            setEventSourcesNumber(newItems.length);
        }
    }, [eventNameToSearch])

    useEffect(() => {
        async function getEventSourcesHelper() {
            await getEventSources(dispatch);
        }

        async function getSubscriptionsHelper() {
            await getSubscriberSubscriptions(tenantName, subscriberName, setSubscriptions)
        }

        if (!eventSources || !eventSources.length) {
            getEventSourcesHelper();
        }

        if (!subscriptions || !subscriptions.length) {
            getSubscriptionsHelper();
        }
    }, []);

    const changePageNumber = (event, currentPage) => {
        setPage(currentPage);
        paginatedEventSources.jump(currentPage);
    };

    useEffect(() => {
        if (subscriptions && subscriptions.length) {
            let newSubscriptionsMap = new Map();
            subscriptions.forEach((subscription) => {
                newSubscriptionsMap.set(subscription.datasetName, subscription.status);
            });
            setSubscriptionsMap(newSubscriptionsMap);
        }
    }, [subscriptions]);

    const handleCheckBoxAll = (newCheckValue) => {
        let newSelectedEvents = new Map();
        if (newCheckValue) {
            eventSources.map((item) => {
                const possibleSub = subscriptionsMap.get(item.name)
                const subStatus = possibleSub ?? "none";
                newSelectedEvents.set(item.name, subStatus);
            })
        }
        setSelectedEventsMap(newSelectedEvents);
        setIsCheckAll(newCheckValue)
        setCanEnable(newSelectedEvents.size)
        setCanDisable(newSelectedEvents.size)
    }

    const onCancelClick = (e) => {
        handleCheckBoxAll(false);
    }

    const onSubscriptionActionClick = async (e, action) => {
        let enableList = new Array()
        let createList = new Array()
        let deleteList = new Array()

        selectedEventsMap.forEach((val, key) => {
            switch (val) {
                case 'disable':
                    enableList.push(key)
                    break
                case 'none':
                    createList.push(key)
                    break
                case 'enable':
                    deleteList.push(key)
                    break
            }
        })

        let requests = [];

        switch (action) {

            case 'enable':
                if (createList.length > 0) {
                    const createBody = JSON.stringify({
                        subscriberNames: [subscriberName],
                        subscriptions: createList,
                        action: 'create'
                    })
                    const createResponse = manageSubscriptions(tenantName, createBody);
                    requests.push(createResponse);
                }

                if (enableList.length > 0) {
                    const enableBody = JSON.stringify({
                        subscriberNames: [subscriberName],
                        subscriptions: enableList,
                        action: 'enable'
                    })
                    const enableResponse = manageSubscriptions(tenantName, enableBody);
                    requests.push(enableResponse)
                }
                break

            case 'disable':
                if (deleteList.length > 0) {
                    const deleteBody = JSON.stringify({
                        subscriberNames: [subscriberName],
                        subscriptions: deleteList,
                        action: 'delete'
                    })
                    const deleteResponse = manageSubscriptions(tenantName, deleteBody);
                    requests.push(deleteResponse)
                    break
                }
        }

        Promise.all(requests)
            .then((results) => {
                let successCount = 0;
                let errorCount = 0;
                results.forEach((result) => {
                    console.log("Status: ", result.status)
                    if (result.status === 200) {
                        successCount++;
                    } else {
                        errorCount++;
                    }
                });
                if (successCount && !errorCount) {
                    setAlertMessage('Success');
                    setSelectedEventsMap(new Map())
                    setIsCheckAll(false)
                    setCanDisable(false)
                    setCanEnable(false)
                } else if (successCount && errorCount) {
                    setAlertMessage('Partial');
                } else {
                    setAlertMessage('Failed');
                }
            }).then(() => {
                getSubscriberSubscriptions(tenantName, subscriberName, setSubscriptions)
            });
    }


    return (
        <div >
            <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
                className={styles.esc_title__div}
            >
                <div className={styles.arrow_back_icon__div}>
                    <Link to={`/dashboard/${tenantName}`}>
                        <ArrowBackIosNew sx={{ pr: 1, color: '#808080' }} className={styles.arrow_back_icon} />
                    </Link>
                </div>

                <Typography className={styles.title}>
                    Subscriber Management
                </Typography>
            </Grid>

            <Grid
                container
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                className={styles.esc_subtitle__div}
            >
                <Typography variant="h5" className={styles.subtitle}>
                    {subscriberName}
                </Typography>

                <div className={styles.esc_header__action_div}>
                    <div className={styles.filtering__div} data-testid="subscriber-management-search-bar-div">
                        <FormSearchField
                            value={eventNameToSearch}
                            onChange={setEventNameToSearch}
                        />
                    </div>
                </div>
            </Grid>

            <Grid container direction={"row"} className={styles.action_bar__grid}>
                <Grid item>
                    <Typography className={styles.description}>
                        *Placeholder for subscriber description
                    </Typography>
                </Grid>

                <Grid item>
                    <div className={styles.action_bar__div} data-testid="subscriber-management-table-action-bar">
                        { selectedEventsMap && selectedEventsMap.size > 0 && <Grid container direction={"row"}>
                            <Grid item>
                                <Button className={styles.action_bar__button}
                                    disabled={!canDisable}
                                    onClick={(e) => onSubscriptionActionClick(e, "disable")}>
                                    Disable
                                </Button>
                            </Grid>

                            <Grid item>
                                <Button className={styles.action_bar__button}
                                    disabled={!canEnable}
                                    onClick={(e) => onSubscriptionActionClick(e, "enable")}>
                                    Enable
                                </Button>
                            </Grid>

                            <Grid item>
                                <Button
                                    className={styles.action_bar__button}
                                    onClick={onCancelClick}
                                >
                                    Cancel
                                </Button>
                            </Grid>
                        </Grid>}
                    </div>
                </Grid>
            </Grid>

            {paginatedEventSources && <Grid className={styles.subscriber_management__table}>
                <SubscriberManagementTable
                    eventSources={paginatedEventSources.currentData()}
                    subscriptions={subscriptionsMap}
                    selectedEvents={selectedEventsMap}
                    setSelectedEvents={setSelectedEventsMap}
                    handleCheckBoxAll={handleCheckBoxAll}
                    isCheckAll={isCheckAll}
                    setCanEnable={setCanEnable}
                    setCanDisable={setCanDisable}
                />
            </Grid>}

            <Grid
                className={styles.pagination}
                container
                direction="row"
                justifyContent="flex-end"
                alignItems="baseline"
            >
                <Typography variant="body2">{page} of {totalPages}</Typography>
                <Pagination
                    renderItem={(item) => (
                        item.type === 'next' || item.type === 'previous' ?
                            <PaginationItem
                                {...item}
                            /> : <></>
                    )}
                    count={totalPages}
                    size={"large"}
                    page={page}
                    onChange={changePageNumber}
                />
            </Grid>

            <div className={styles.alerts__div}>
                {alertMessage && alertMessage === 'Success' ?
                    <Alert
                        severity="success"
                        onClose={() => { setAlertMessage(null) }}
                    >
                        Subscriptions updated successfully
                    </Alert> :
                    <></>
                }
                {alertMessage && alertMessage === 'Failed' ?
                    <Alert
                        severity="error"
                        onClose={() => { setAlertMessage(null) }}
                    >
                        Subscriptions failed to update
                    </Alert> :
                    <></>
                }
                {alertMessage && alertMessage === 'Partial' ?
                    <Alert
                        severity="warning"
                        onClose={() => { setAlertMessage(null) }}
                    >
                        Some subscriptions failed to update
                    </Alert> :
                    <></>
                }
            </div>
        </div>
    )
}

export default SubscriberManagement;