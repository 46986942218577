import React, { useEffect, useState } from 'react'
import styles from '../tables-global.module.css';
import { Checkbox, Chip, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { Button } from '@mui/base';
import local_style from "./SubscriberManagementTable.module.css"
import { Link } from "react-router-dom";
import { SUBSCRIBER_MANAGEMENT_TABLE_CHIP_COLORS } from '../../../utils/constants';


const SubscriberManagementTable = ({
    eventSources,
    subscriptions,
    selectedEvents,
    setSelectedEvents,
    handleCheckBoxAll,
    isCheckAll,
    setCanEnable,
    setCanDisable }) => {

    const handleCheckBox = (selectedEventName) => {
        let newSelectedEvents = new Map(JSON.parse(JSON.stringify(Array.from(selectedEvents))));
        if (newSelectedEvents.get(selectedEventName)) {
            newSelectedEvents.delete(selectedEventName);
        }
        else {
            // Set if this event already has a subscription
            // to this subscriber and what is the status of it
            const possibleSub = subscriptions.get(selectedEventName)
            const subStatus = possibleSub ?? "none";
            newSelectedEvents.set(selectedEventName, subStatus);
        }
        setSelectedEvents(newSelectedEvents);

        // Check if there is at least one selected event
        // that can be enable or disable
        const values = [...newSelectedEvents.values()];
        setCanDisable(values.includes("enable"))
        setCanEnable(values.includes("disable") || values.includes("none"))
    }

    const handleCheckBoxAllHelper = (newCheckValue) => {
        handleCheckBoxAll(newCheckValue);
    }

    return (
        <div data-testid="subscriber-management-table">
            <TableContainer className={styles.table_container}>
                <Table stickyHeader className={styles.esc__table}>
                    <TableHead className={styles.esc__table_head}>
                        <TableRow className={styles.esc__table_header__row}>

                            <TableCell className={`${styles.esc__table_header} ${local_style.esc__table__name} ${styles.esc__table_cell} ${local_style.table_type}`}>
                                <Checkbox
                                    checked={isCheckAll}
                                    onChange={(e) => handleCheckBoxAllHelper(e.target.checked)}
                                    inputProps={{ "aria-label": "controlled" }}
                                    size="small"
                                />
                                <span className={local_style.esc__table__name}>Event Notifications</span>
                            </TableCell>

                            <TableCell className={`${styles.esc__table_header} ${styles.esc__table_header__description}`} align="left">
                                <div className={styles.esc__table_header__row__div}>
                                    <div className={styles.esc__table_header__divider}></div>
                                    <div className={styles.esc__table_header__title}>
                                        Description
                                    </div>
                                </div>
                            </TableCell>

                            <TableCell className={`${styles.esc__table_header} ${styles.esc__table_header__action}`} align="left">
                                <div className={styles.esc__table_header__row__div}>
                                    <div className={styles.esc__table_header__divider}></div>
                                    <div className={`${styles.esc__table_header__title} ${styles.esc__table_action}`}>
                                        Status
                                    </div>
                                </div>
                            </TableCell>

                        </TableRow>
                    </TableHead>
                    <TableBody className={styles.esc__table_body}>
                        {eventSources && eventSources.length ? eventSources.map((item, index) => {
                            let subscriptionStatus = "disabled"
                            let possibleSubscription = subscriptions.get(item.name)
                            subscriptionStatus = (possibleSubscription && possibleSubscription === "enable") ? "enabled" : "disabled";
                            return (<TableRow
                                key={index}
                                className={styles.esc__table__row}
                            >
                                <TableCell
                                    className={`${styles.esc__table__cell} ${local_style.esc__table__name}`}
                                    component="th" scope="row"
                                >
                                    <div className={`${styles.esc__table__name_div}`}>
                                        {selectedEvents && <Checkbox
                                            id={`subscriber-management-table--checkbox--${item.id}`}
                                            data-testid={`subscriber-management-table--checkbox--${item.id}`}
                                            checked={typeof selectedEvents.get(item.name) !== "undefined"}
                                            onChange={(e) => handleCheckBox(item.name)}
                                            inputProps={{ "aria-label": "controlled" }}
                                            size="small"
                                        />}
                                        <span className={local_style.esc__table__name}>{item.name}</span>
                                    </div>
                                </TableCell>
                                <TableCell
                                    className={`${styles.esc__table__cell} ${local_style.esc__table_description}`}
                                    component="th" scope="row"
                                >
                                    <span>{item.description}</span>
                                </TableCell>
                                <TableCell
                                    className={`${styles.esc__table__cell} ${local_style.esc__table__subscriptions}`}
                                    component="th" scope="row"
                                >
                                    <div className={local_style.status__col}>
                                        <Chip
                                            className={local_style.status_chip}
                                            label={subscriptionStatus}
                                            sx={{
                                                backgroundColor: SUBSCRIBER_MANAGEMENT_TABLE_CHIP_COLORS[subscriptionStatus ?? "enabled"].backgroundColor,
                                                color: SUBSCRIBER_MANAGEMENT_TABLE_CHIP_COLORS[subscriptionStatus ?? "enabled"].color
                                            }}
                                        />
                                    </div>
                                </TableCell>

                            </TableRow>
                            )
                        }) : <div className={local_style.empty_items__div}>No subscriptions</div>}
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    );
}

export default SubscriberManagementTable;