export const SUBSCRIBER_TYPES = [
    {
        value: 'cradle',
        name: 'Cradle'
    },
    {
        value: 'sns',
        name: 'SNS'
    },
]

export const TEXTAREA_CHARACTER_LIMIT = 250;

export const VALID_TEXTFIELD_BORDER_COLOR = '#808080';

export const LAMBDA_MAX_LENGTH_OF_NAME = 64;

// Length of 'GFTHydrosPubSub- -' prefix for
// subscribers name
export const LAMBDA_PREFIX_NAME = 17

export const SUBSCRIBER_MANAGEMENT_TABLE_CHIP_COLORS = {
    enabled: {
        backgroundColor: '#EBFAEB',
        color: '#196619'
    },
    disabled: {
        backgroundColor: '##F2F2F2',
        color: '#808080'
    }
}

