import React, { useEffect, useState } from 'react';

function usePagination(newData, newItemsPerPage) {
    const [data, setData] = useState([]);
    const [itemsPerPage, setItemsPerPage] = useState(12);
    const [currentPage, setCurrentPage] = useState(1);
    const maxPage = Math.ceil(data.length / itemsPerPage);

    useEffect(() => {
        setData(newData);
    }, [newData])

    useEffect(() => {
        setItemsPerPage(newItemsPerPage);
    }, [newItemsPerPage])

    function currentData() {
        const begin = (currentPage - 1) * itemsPerPage;
        const end = begin + itemsPerPage;
        return data.slice(begin, end);
    }

    function next() {
        setCurrentPage(currentPage => Math.min(currentPage + 1, maxPage));
    }

    function prev() {
        setCurrentPage(currentPage => Math.max(currentPage - 1, 1));
    }

    function jump(page) {
        const pageNumber = Math.max(1, page);
        setCurrentPage(currentPage => Math.min(pageNumber, maxPage));
    }

    function updateData(newItems) {
        setData(newItems)
    }

    function updateItemsPerPage(newItemsAmount) {
        setItemsPerPage(newItemsAmount)
    }

    return { next, prev, jump, currentData, currentPage, maxPage, updateData, updateItemsPerPage };
}

export default usePagination;
