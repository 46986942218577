// React import
import { useEffect, useState } from 'react';

// 3rd Party-Libraries import
import { Modal, Box, Typography, Button } from '@mui/material';

// Local import
import { LAMBDA_MAX_LENGTH_OF_NAME, LAMBDA_PREFIX_NAME, SUBSCRIBER_TYPES } from '../../../utils/constants';
import FormTextField from '../../FormFields/FormTextField/FormTextField';
import FormDropdownField from '../../FormFields/FormDropdownField/FormDropdownField';
import ModalButton from '../../Buttons/ModalButtons/ModalButton';
import styles from './CreateSubscriberModal.module.css';
import { createSubscriber } from '../../../services/SubscribersService';
import { isStringValid } from '../../../utils/sharedFunctions';


const CreateSubscriberModal = ({ isOpen = false, handleClose, tenantName, dispatch, setMessage }) => {

    const [name, setName] = useState('');
    const [description, setDescription] = useState('');

    // Cradle fields
    const [profileID, setProfileID] = useState('');
    const [jobID, setJobID] = useState('');

    // SNS fields
    const [snsTopicArn, setSnsTopicArn] = useState('');

    const [typeOption, setTypeOption] = useState('');
    const [isFormValid, setIsFormValid] = useState(false);

    const [nameErrorText, setNameErrorText] = useState('');


    const handleTypeChange = (e) => {
        setTypeOption(e.target.value)
    }

    // Check if subscriber name is empty or contain invalid characters
    const isSubscriberNameValid = () => {
        return ((!name.length) || (name.length && isStringValid(name)));
    }


    // Check if required fields are non-empty
    // before enabling the submit option in the modal,
    // and update the value of isFormValid only when
    // strictly necessary
    useEffect(() => {

        // update nameErrorText variable
        // based on current state of name
        if(isSubscriberNameValid()) setNameErrorText('');
        else{
            setNameErrorText('Name can only contain letters, digits, hyphens, and underscores')
            setIsFormValid(false);
            return;
        }

        // Check parameters based on typeOption value
        if (name.length && description.length) {
           
                if (typeOption == 'cradle') {
                    if ((!jobID.length || !profileID.length) && isFormValid) {
                        setIsFormValid(false)
                    }
                    else if (jobID.length && profileID.length && !isFormValid) {
                        setIsFormValid(true)
                    }
                }
                else if (typeOption == 'sns') {
                    if (!snsTopicArn.length && isFormValid) {
                        setIsFormValid(false)
                    }
                    else if (snsTopicArn.length && !isFormValid) {
                        setIsFormValid(true)
                        return;
                    }
                }
                else if (!typeOption.length && isFormValid) {
                    setIsFormValid(false)
                }
                else if (isFormValid) {
                    setIsFormValid(false)
                }
            }
            else {
                if (isFormValid) {
                    setIsFormValid(false)
            }
        }
    }, [name, description, typeOption, profileID, jobID, snsTopicArn, nameErrorText])

    // Reset fields when modal is closed
    const handleCloseHelper = () => {
        // General fields
        setName('');
        setDescription('');
        setTypeOption('');

        // Cradle fields
        setJobID('');
        setProfileID('');

        // SNS fields
        setSnsTopicArn('');// this parameter is optional

        handleClose();
    }

    // Create subscriber on submit modal
    const createSubscriberHelper = async (e) => {


        e.preventDefault();
        var subscriberParams = {}

        // Create subscriber params based on
        // typeOption

        if (typeOption === 'cradle') {
            subscriberParams = {
                "cradleProfileId": profileID,
                "cradleJobId": jobID
            }
        }

        if (typeOption === 'sns') {
            subscriberParams = {
                snsTopicArn,
            }
        }

        const body = JSON.stringify({
            subscriptions: [],
            action: "create",
            subscriberName: name,
            subscriberType: typeOption,
            subscriberParams
        })
        await createSubscriber(tenantName, body, dispatch, setMessage)
        handleCloseHelper()
    }

    return (
        <Modal
            open={isOpen}
            onClose={handleCloseHelper}
        >
            <Box className={styles.modal__box} sx={{ boxShadow: 3 }}>
                <Typography className={styles.modal__title}>
                    Add New Subscriber
                </Typography>

                <div className={styles.modal__form_div}>
                    <div className={styles.modal__form__field_div}>
                        <FormTextField
                            label='Name*'
                            value={name}
                            setter={setName}
                            hasCharactersLimit={true}
                            charactersLimit={LAMBDA_MAX_LENGTH_OF_NAME - (tenantName?.length??0) - LAMBDA_PREFIX_NAME}
                            errorText={nameErrorText}
                        />
                    </div>

                    <div
                        className={styles.modal__form__field_div} >
                        <FormTextField
                            label='Description*'
                            multiline={true}
                            rows={4}
                            value={description}
                            setter={setDescription}
                            hasCharactersLimit={true}
                        />
                    </div>

                    <div className={styles.modal__row}>

                        <div className={`${styles.modal__form__field_div} ${styles.modal__form__col__field_div}`}>
                            <FormDropdownField
                                label='Type*'
                                options={SUBSCRIBER_TYPES}
                                value={typeOption}
                                handleChange={(e) => handleTypeChange(e)}
                            />
                        </div>

                        {typeOption == 'cradle' ?
                            <>
                                <div className={`${styles.modal__form__field_div} ${styles.modal__form__col__field_div}`}>
                                    <FormTextField
                                        label='Profile ID*'
                                        value={profileID}
                                        setter={setProfileID} />
                                </div>

                                <div className={`${styles.modal__form__field_div} ${styles.modal__form__col__field_div}`}>
                                    <FormTextField
                                        label='Job ID*'
                                        value={jobID}
                                        setter={setJobID}
                                    />
                                </div>
                            </> : <></>}

                        {typeOption == 'sns' ?
                            <>
                                <div className={`${styles.modal__form__field_div} ${styles.modal__form__col__field_div}`}>
                                    <FormTextField
                                        label='Topic Arn* '
                                        value={snsTopicArn}
                                        setter={setSnsTopicArn} />
                                </div>
                            </> : <></>}


                    </div>

                    <div className={styles.modal__buttons_row}>
                        <div className={styles.modal__button_div}>
                            <ModalButton
                                name='Cancel'
                                onClick={(e) => handleCloseHelper()}
                            />
                        </div>

                        <div
                            className={styles.modal__button_div}>
                            <ModalButton
                                name='Submit'
                                isDisabled={!isFormValid}
                                onClick={(e) => createSubscriberHelper(e)}
                            />
                        </div>
                    </div>
                </div>
            </Box>
        </Modal>
    );
}

export default CreateSubscriberModal;