import {configureStore} from "@reduxjs/toolkit";
import tenantReducer from "./slices/tenantSlice";
import eventSourcesReducer  from "./slices/eventSourceSlice";
import subsciberReducer from "./slices/subscriberSlice";

export const store = configureStore({
   reducer: {
       tenants: tenantReducer,
       eventSources: eventSourcesReducer,
       subscribers: subsciberReducer,
   }
});