// 3rd Party-Libraries import
import { TextField } from '@mui/material';

// Local import
import styles from './FormTextField.module.css'
import { TEXTAREA_CHARACTER_LIMIT, VALID_TEXTFIELD_BORDER_COLOR } from '../../../utils/constants';


const FormTextField = ({ label = '', multiline = false, rows = 1, value = '',
                         setter = () => { }, hasCharactersLimit = false, size='medium',  charactersLimit=null, errorText=''}) => {

  return (
    <TextField
      label={label}
      className={`${styles.form_text_field}`}
      size={size}
      sx={{
        "& .MuiInputLabel-root": {
          color: '#757575',
          fontFamily: 'Amazon Ember',
          fontStyle: 'normal',
          fontWeight: '400',
          fontSize: '16px',
        },
        "& .MuiOutlinedInput-root": {
          "& > fieldset": { borderColor: VALID_TEXTFIELD_BORDER_COLOR },
        },
        "& .MuiOutlinedInput-root.Mui-disabled": {
          "& > fieldset": { borderColor: VALID_TEXTFIELD_BORDER_COLOR }
        }
      }}
      inputProps={{
        maxLength: hasCharactersLimit?(charactersLimit??TEXTAREA_CHARACTER_LIMIT):null
      }}
      multiline={multiline}
      rows={rows}
      value={value}
      onChange={(e) => setter(e.target.value)}
      helperText={(hasCharactersLimit ? `${value.length}/${(charactersLimit??TEXTAREA_CHARACTER_LIMIT)}` : '') + (errorText.length?(' ' + errorText) : '')}
      errorText={errorText}
      error = {errorText.length}
    />
  );
}

export default FormTextField;