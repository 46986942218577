// React Libreries
import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'

// Local components imports
import reportWebVitals from './reportWebVitals';
import Layout from './components/Layouts/Layout';
import Tenants from './pages/Tenants/Tenants';
import Dashboard from './pages/Dashboard/Dashboard';
import EventSourceCatalog from "./pages/EventSourceCatalog/EventSourceCatalog";

// Redux imports
import { Provider } from "react-redux"
import { store } from "./redux/store";
import SubscriberManagement from './pages/SubscriberManagement/SubscriberManagement';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <Provider store={store}>
        <Layout>
            <Router>
                <Routes>
                    <Route exact path="/" element={<Tenants />} />
                    <Route path="/dashboard/:tenantName" element={<Dashboard />} />
                    <Route path="/event-source-catalog/:tenantName" element={<EventSourceCatalog />} />
                    <Route path="/subscribers-management/:tenantName/:subscriberName" element={<SubscriberManagement />} />
                </Routes>
            </Router>
        </Layout>

    </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
