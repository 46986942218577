import React from "react"
import styles from './FormSearchField.module.css'
import { TextField } from "@mui/material";
import { VALID_TEXTFIELD_BORDER_COLOR } from "../../../utils/constants";

const FormSearchField = ({ value, onChange }) => {
    return (
        <TextField
            label="Search"
            className={`${styles.form_text_field}`}
            size="small"
            sx={{
                "& .MuiInputLabel-root": {
                    color: '#757575',
                    fontFamily: 'Amazon Ember',
                    fontStyle: 'normal',
                    fontWeight: '400',
                    fontSize: '13px',
                    lineHeight: "12px"
                },
                "& .MuiOutlinedInput-root": {
                    "& > fieldset": {
                        borderColor: VALID_TEXTFIELD_BORDER_COLOR,
                        height: "32px"
                    },
                },
                "& .MuiOutlinedInput-root.Mui-disabled": {
                    "& > fieldset": { borderColor: VALID_TEXTFIELD_BORDER_COLOR }
                },
                "& .MuiOutlinedInput-root.Mui-focused": {
                    "& > fieldset": {
                        borderColor: VALID_TEXTFIELD_BORDER_COLOR,
                        border: `1px solid`
                    }
                },
                "& .MuiOutlinedInput-input": {
                    height: "12px"
                }
            }}
            value={value}
            onChange={(e) => onChange(e.target.value)}
        />
    );
}

export default FormSearchField;