import React, { useEffect, useState } from 'react'
import styles from './EventSourceTable.module.css';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { Button } from '@mui/base';


const EventSourceTable = ({ items, onClick }) => {
    return (
        <TableContainer className={styles.table_container}>
            <Table stickyHeader className={styles.esc__table}>
                <TableHead className={styles.esc__table_head}>
                    <TableRow className={styles.esc__table_header__row}>
                        <TableCell className={`${styles.esc__table_header} ${styles.esc__table__name} ${styles.esc__table_cell}`}>
                            Event Notifications
                        </TableCell>
                        <TableCell className={`${styles.esc__table_header} ${styles.esc__table_header__description}`} align="left">
                            <div className={styles.esc__table_header__row__div}>
                                <div className={styles.esc__table_header__divider}></div>
                                <div className={styles.esc__table_header__title}>
                                    Description
                                </div>
                            </div>
                        </TableCell>
                        <TableCell className={`${styles.esc__table_header} ${styles.esc__table_header__action}`} align="left">
                            <div className={styles.esc__table_header__row__div}>
                                <div className={styles.esc__table_header__divider}></div>
                                <div className={`${styles.esc__table_header__title} ${styles.esc__table_action}`}>
                                    Action
                                </div>
                            </div>
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody className={styles.esc__table_body}>
                    {items.map((eventSource, eventSourceIdx) => {
                        return (<TableRow
                            key={eventSourceIdx}
                            className={styles.esc__table__row}
                        >
                            <TableCell
                                className={`${styles.esc__table__cell} ${styles.esc__table__name}`}
                                component="th" scope="row"
                            >
                                <div className={styles.esc__table__name_row}>
                                    <div className={styles.esc__table__name_pill}>
                                        <span className={styles.esc__table__name_pill__span}>
                                            pill
                                        </span>
                                    </div>
                                    <div className={styles.esc__table__name_div}>
                                        {eventSource.name}
                                    </div>
                                </div>
                            </TableCell>
                            <TableCell
                                className={`${styles.esc__table__cell} ${styles.esc__table_description}`}
                                component="th" scope="row"
                            >
                                <span>{eventSource.description}</span>
                            </TableCell>
                            <TableCell
                                align="center"
                                className={styles.esc__table__cell}
                                component="th" scope="row"
                            ><div>
                                    <Button
                                        className={styles.button}
                                        onClick={(e) => onClick(eventSource)}>
                                        <span className={styles.button__span}>
                                            Manage
                                        </span>
                                    </Button>
                                </div>
                            </TableCell>

                        </TableRow>
                        )
                    })}
                </TableBody>
            </Table>
        </TableContainer>
    );
}

export default EventSourceTable;