import {createSlice} from "@reduxjs/toolkit"

const initialState = {
    tenant: null,
    subscribers: [],
    resolved: false
}

export const subsciberReducer = createSlice({
    name: 'subscribers',
    initialState,
    reducers: {
        updateSubscribers: (state, action) => {
            state.tenant = action.payload.tenant
            state.subscribers = action.payload.subscribers
            state.resolved = action.payload.resolved
        }
    }
});

export const {updateSubscribers} = subsciberReducer.actions;

export default subsciberReducer.reducer;